import { wrapRootElement as wrap } from "./src/components/RootElementWrapper"

// custom typefaces
import "@fontsource/montserrat"
import "@fontsource/open-sans"

// custom CSS styles
import "./src/global.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import "pure-react-carousel/dist/react-carousel.es.css"

export const wrapRootElement = wrap
