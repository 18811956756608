import React from "react"
import { MDXProvider } from "@mdx-js/react"

const Heading1 = props => (
  <h1 className="font-display text-4xl mb-8 font-bold" {...props}>
    {props.children}
  </h1>
)

const Heading2 = props => (
  <h2 className="font-display text-2xl mt-8 mb-4 font-bold" {...props}>
    {props.children}
  </h2>
)

const Heading3 = props => (
  <h3 className="font-display text-xl mt-6 mb-3 font-bold" {...props}>
    {props.children}
  </h3>
)

const Anchor = props => (
  <a className="text-gold-dark hover:text-gold" {...props}>
    {props.children}
  </a>
)

const UnorderedList = props => (
  <ul className="list-outside list-disc" {...props} />
)

const ListItem = props => <li {...props} />

const Paragraph = props => <p className="text-base mb-2" {...props} />

const components = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  p: Paragraph,
  a: Anchor,
  ul: UnorderedList,
  li: ListItem,
}

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)
